<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-17 18:06:48
 * @LastEditTime: 2022-12-11 14:08:54
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="container">
    <PublicHeader :totalName="$t('auth').title" />
    <div class="content">
      <div class="title">{{ $t('auth').low }}</div>
      <div
        class="btn"
        @click="$router.push({ name: 'Auth' })"
        v-if="userInfo.LowerAuthenticationStatus !== 'Success'"
      >
        {{ getStatus(userInfo.LowerAuthenticationStatus) }}
      </div>
      <div v-else class="btn success">{{ $t('auth').success }}</div>
    </div>
    <div class="content" v-if="basicConfig.IsHighAuthentication">
      <div class="title">{{ $t('auth').high }}</div>
      <div
        class="btn"
        @click="handleclick(userInfo.HighAuthenticationStatus)"
        v-if="userInfo.HighAuthenticationStatus !== 'Success'"
      >
        {{ getStatus(userInfo.HighAuthenticationStatus) }}
      </div>
      <div v-else class="btn success">{{ $t('auth').success }}</div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      userInfo: {}
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  async mounted() {
    this.userInfo = await userApi.userInfo()
  },
  methods: {
    handleclick(status) {
      console.log(status)
      if (status === 'Review' || status === 'Success') {
        return
      }
      if (this.userInfo.LowerAuthenticationStatus === 'Success') {
        this.$router.push({ name: 'HighAuth' })
      } else {
        this.$toast(this.$t('auth').tips)
      }
    },
    getStatus(status) {
      if (status === 'No') {
        return this.$t('auth').qrz
      }
      if (status === 'Review') {
        return this.$t('auth').shz
      }
      if (status === 'Success') {
        return this.$t('auth').success
      }
      if (status === 'Fail') {
        return this.$t('auth').rzsb
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 45px;
  .content {
    background: #17181e;
    border-radius: 10px;
    margin: 10px 20px;
    padding: 20px 20px 30px;
    .title {
      font-size: 18px;
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px auto 0;
      height: 35px;
      border-radius: 5px;
      width: 100%;
      background: #8e1473;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
</style>
